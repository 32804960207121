.footer {
  bottom: 0;
  position: sticky;
  width: 100%;
  line-height: 3rem;
  background-color: #f5f5f5;
  margin-top: 1rem;
}

.social-media-link {
    color: #000000;
    margin: 0 0.5rem;
}