.centred-image {
    margin: 0 auto;
    display: block;
}

.card {
    flex-grow: 1;
}

.row {
    display: flex;
}